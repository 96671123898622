@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --fc-button-text-color: #8b94ca !important;
  --fc-button-bg-color: #fff !important;
  --fc-button-border-color: #8b94ca !important;
  --fc-button-hover-bg-color: #eff2ff !important;
  --fc-button-hover-border-color: #32418f !important;
  --fc-button-active-bg-color: #32418f !important;
  --fc-button-active-border-color: #32418f !important;
  --fc-today-bg-color: #eff2ff !important;
}

html,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Kanit", sans-serif;
  font-size: 14px;
  min-height: 100%;
  height: 100%;
}

.date-picker,
.date-picker > div.react-datepicker-wrapper,
.date-picker
  > div
  > div.react-datepicker__input-container
  .date-picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.image-container {
  height: 200px;
  width: 200px;
  cursor: pointer;
  position: relative;
}

.image,
.image-no-hover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 14px;
  /* padding: 16px; */
}

.image-container .delete {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container .delete-multiple {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.image:hover {
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.image-container:hover .delete {
  display: block;
  z-index: 100;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: "100%";
  width: "100%";
  opacity: 0;
  transition: 0.5s ease;
  background-color: #7474748c;
  border-radius: 14px;
}

.image-container:hover .overlay {
  opacity: 0.5;
}

.ag-watermark {
  display: none !important;
  opacity: 0 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: #fff !important;
}

.fc-toolbar-chunk:nth-child(2) {
  display: flex;
  gap: 16px;
  align-items: center;
}

.fc .fc-toolbar-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.25em;
  color: #32418f;
  /* text-decoration: underline; */
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0 !important;
}

.fc-timegrid-event .fc-event-main {
  overflow: hidden !important;
}

.fc-daygrid-event {
  border-radius: 16px;
  border-color: #32418f99 !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: #eff2ff;
}

@page {
  size: A4;
}

@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
}
